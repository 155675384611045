<template>
    <div class="app-main__inner">
        <div class="container  mb-3">
            <div class="row" v-if="checking">
                <span class="col-md-12 flex justify-center text-center mt-10">
                    <svg class="animate-spin -ml-1 mr-3 h-8 w-8 text-gray-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                    <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                    </svg>
                </span>
            </div>
            <div class="row p-5" v-else>
                
                <div class="col-md-12 text-center" v-if="successCheck">
                    <div class="flex justify-center">
                        <svg class="h-40 w-40 text-green-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>
                    </div>
                    <div class="text-xl link-theme-color mt-4 text-gray-600 mb-3">
                        {{ $t('visma' )}}
                    </div>
                    <div>
                        <router-link :to="{ name : 'appsettings', query: { key : sub.id, type : sub.app_type }, params : { type : sub.app_type } }" class="btn btn-theme-custom hover:text-white">{{ $t('subscriptionPage') }}</router-link>
                    </div>
                </div>
                <div class="col-md-12 text-center" v-else>
                    <div class="flex justify-center">
                        <svg class="h-40 w-40 text-red-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>
                    </div>
                    <div class="text-xl font-light link-theme-color mt-4 text-gray-600">
                        {{ $t('invalidSession') }}
                    </div>
                </div>
            </div>
        </div>
        <Notification />
    </div>
</template>

<script>
    import { mapGetters } from 'vuex'

    export default {
        name : 'visma-success',
        components : {
            Notification : () => import('./../../customComponent/NotificationAlert'),
        },
        data () {
            return {
                PaymentType : null,
                history : [],
                sub : {},
                checking : true,
                successCheck : true,
                errorCheck : true,
                updating : false,
                paymentCardModal : false,
                processingCard : false
            }
        },
        computed : {
            ...mapGetters({
                USER_TOKEN : 'auth/USER_TOKEN',
                USER_REFRESH : 'auth/USER_REFRESH',
                GET_USER_COMPANY_ID : 'customer/GET_USER_COMPANY_ID',
                GET_COMPANY_BILLING : 'customer/GET_COMPANY_BILLING',
                GET_PAYMENT_TYPE : 'customer/GET_PAYMENT_TYPE',
            })
        },
        mounted () {
            this.checking = true

            setTimeout(() => {
                this.checking = false
                const data = window.sessionStorage.getItem('vsubs')
                this.sub = JSON.parse(data)
            }, 1500)
        },
        beforeDestroy () {
            window.sessionStorage.removeItem('vsubs')
        },
        methods : {
            
        }
    }
</script>

<style lang="scss" scoped>
.custom-btn-pay{
    background-color: #F167CA !important;
    border-color: #F167CA !important;
    font-weight: 800;
}
.custom-card-section{
    box-shadow: 0 0.46875rem 2.1875rem rgba(4,9,20,0.03),0 0.9375rem 1.40625rem rgba(4,9,20,0.03),0 0.25rem 0.53125rem rgba(4,9,20,0.05),0 0.125rem 0.1875rem rgba(4,9,20,0.03);
    border-width: 0;
    transition: all .2s;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(26,54,126,0.125);
}
.custom-save-btn{
    background-color: #2CDCA7 !important;
    border-color: #2CDCA7 !important;
    font-weight: 800;
}
.override-form-field{
    border: 0;
    border-bottom: 2px solid rgb(64, 138, 235);
    box-shadow: none;
    background-color: #fff !important;
    border-radius: unset !important;
}
.col-form-label {
    padding-top: 1rem !important;
}
</style>