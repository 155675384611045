<template>
	<div class="app-main__inner">
		<div class="main-card mb-3">

			<div class="row mb-3 mt-3">
				<div class="col-md-6">
					<ul class="body-tabs mb-2 body-tabs-layout  nav">
						<!--tabs-animated body-tabs-animated-->
						<li class="nav-item " v-if="showOrderBtn" v-on:click="switchTab(1)">
							<a role="tab" class="tab-btn nav-link" :class="{'active': tab === 1}" id="tab-0"
							   data-toggle="tab"
							   href="#tab-content-0"
							   aria-selected="true">
								<span class="text-xs-custom">{{ $t('orders') }}</span>
							</a>
						</li>
						<li class="nav-item " v-if="showProductBtn" v-on:click="switchTab(2)">
							<a role="tab" class="tab-btn nav-link" :class="{'active': tab === 2}" id="tab-1"
							   data-toggle="tab"
							   href="#tab-content-1"
							   aria-selected="false">
								<span class="text-xs-custom">{{ $t('article') }}</span>
							</a>
						</li>
					</ul>
				</div>
				<div class="col-md-6">
					<div class="flex justify-content-end">
						<!--							<button v-on:click="onOpenDrawer"-->
						<!--									class="float-right c-btn mr-2 btn-white bg-white text-primary btn text-sm font-black btn-lg border btn-border-all btn-full-theme text-white hover:text-blue-900">-->
						<!--								<span class="text-xs-custom">{{ $t('triggerSync') }}</span>-->
						<!--							</button>-->
						<router-link :to="{name: 'app-synchronize'}"
									 class="float-right c-btn btn-primary btn text-sm font-black btn-lg border btn-border-all btn-full-theme text-white hover:text-blue-900">
							<span class="text-xs-custom">{{ $t('AppSynchronizePage') }}</span>
						</router-link>
					</div>
				</div>
			</div>


			<div v-if="tab ===1">
				<div class="contaner">
					<div class="main-card mb-3 card">
						<div class="card-body">
							<div class="row align-items-center sticky-able mb-3 mt-3">
								<div class="col-md-4 align-items-center -mt-4">
                                            <span class="-mt-2">
                                                <el-date-picker
													class="w-full text-xs font-light app-input"
													v-model="value1"
													type="daterange"
													@change='dateFilterOrder'
													value-format="yyyy-MM-dd"
													format="yyyy/MM/dd"
													start-placeholder="Startdatum"
													end-placeholder="Slutdatum">
                                                </el-date-picker>
                                            </span>
								</div>
								<div class="col-md-4 align-items-center">
									<div class="flex w-full">
										<!-- <div class="flex text-xs font-light justify-between align-items-center mr-1 -mt-4">

										</div> -->
										<div class="form-group w-full">
											<select v-model="sub" @change="changeAppName($event, 'order')"
													style="padding-top:0.6rem !important"
													class="text-xs form-select w-full py-2 pt-2">
												<option value="">{{ $t('chooseSubscription') }}:</option>
												<!-- <option value="all" >{{ $t('all') }}</option> -->
												<option :value="app.id" v-for="(app, i) in orderSubscription"
														:key="i">{{ app.app_type_name }}
												</option>
											</select>
										</div>
									</div>
								</div>
								<div class="col-md-4 align-items-center">
									<div class="flex justify-start lg:justify-end">
										<div class="form-group w-full">
											<input @input="searchInputFilterOrder($event, 'order')" type="text"
												   :placeholder="$t('search')"
												   class="form-input w-full h-8 text-xs"
												   style="height: 32px !important;">
										</div>
									</div>
								</div>
							</div>

						</div>
					</div>
				</div>
				<div class="w-full">
					<div v-if="vloading" class="flex justify-center w-full">
						<svg class="animate-spin -ml-1 mr-3 h-5 w-5 text-gray-400"
							 xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
							<circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
									stroke-width="4"></circle>
							<path class="opacity-75" fill="currentColor"
								  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
						</svg>
					</div>
					<div class="table-container w-full bg-white lg:max-w-[1242px] overflow-x-scroll">
						<table
							class="table table-hover bg-white table-striped table-bordered table-auto overflow-x-scroll w-full block">
							<thead>
							<tr>
								<th class="text-xs">
												<span class="flex justify-between px-2">
														<span>
														{{ $t('orderNumber') }}
													</span>
													<span class="relative">
													<i class="fa fa-chevron-up absolute bottom-2 cursor-pointer icon-size"
													   v-on:click.prevent="sortOrder('number_in_app')"></i>
													<i class="fa fa-chevron-down absolute top-2 cursor-pointer icon-size"
													   v-on:click.prevent="sortOrder('-number_in_app')"></i>
												</span>
													</span>
								</th>
								<th class="text-xs">
												<span class="flex justify-between px-2">
														<span>
														{{ $t('customer') }}
													</span>
													<span class="relative">
													<i class="fa fa-chevron-up absolute bottom-2 cursor-pointer icon-size"
													   v-on:click.prevent="sortOrder('customer__name')"></i>
													<i class="fa fa-chevron-down absolute top-2 cursor-pointer icon-size"
													   v-on:click.prevent="sortOrder('-customer__name')"></i>
												</span>
													</span></th>
								<th class="text-xs">
												<span class="flex justify-between px-2">
														<span>
														{{ $t('subscription') }}
													</span>
													<span class="relative">
													<i class="fa fa-chevron-up absolute bottom-2 cursor-pointer icon-size"
													   v-on:click.prevent="sortOrder('subscription_id')"></i>
													<i class="fa fa-chevron-down absolute top-2 cursor-pointer icon-size"
													   v-on:click.prevent="sortOrder('-subscription_id')"></i>
												</span>
													</span></th>
								<th class="text-xs">
												<span class="flex justify-between px-2">
														<span>
														{{ $t('amount') }}
													</span>
													<span class="relative">
													<i class="fa fa-chevron-up absolute bottom-2 cursor-pointer icon-size"
													   v-on:click.prevent="sortOrder('total_price')"></i>
													<i class="fa fa-chevron-down absolute top-2 cursor-pointer icon-size"
													   v-on:click.prevent="sortOrder('-total_price')"></i>
												</span>
													</span></th>
								<th class="text-xs">
												<span class="flex justify-between px-2">
														<span>
														{{ $t('currency') }}
													</span>
													<span class="relative">
													<i class="fa fa-chevron-up absolute bottom-2 cursor-pointer icon-size"
													   v-on:click.prevent="sortOrder('currency')"></i>
													<i class="fa fa-chevron-down absolute top-2 cursor-pointer icon-size"
													   v-on:click.prevent="sortOrder('-currency')"></i>
												</span>
													</span></th>
								<th class="text-xs">
											<span class="flex justify-between px-2">
														<span>
														{{ $t('lastUpdated') }}
													</span>
														<span class="relative">
													<i class="fa fa-chevron-up absolute bottom-2 cursor-pointer icon-size"
													   v-on:click.prevent="sortOrder('date_updated')"></i>
													<i class="fa fa-chevron-down absolute top-2 cursor-pointer icon-size"
													   v-on:click.prevent="sortOrder('-date_updated')"></i>
												</span>
													</span></th>
								<th class="text-xs"><span class="flex justify-between px-2">
														<span>
														{{ $t('status') }}
													</span>
													</span></th>
								<th class="text-xs">{{ $t('reason') }}</th>
								<th class="text-xs">{{ $t('accounting') }}</th>
							</tr>
							</thead>
							<tbody>
							<OrderTableRowVue @removeOrder="removeOrder"
											  :company-id="GET_USER_COMPANY_ID"
											  :data="order"
											  :show-delete="false"
											  v-for="(order, i) in orders.results" :key="i"/>
							</tbody>
						</table>
					</div>
				</div>
				<div class="mb-5 mt-3" v-if="orders.results.length > 0">
					<div class="flex justify-between">
						<div>
							<select class="text-xs form-select w-full removs-bg" v-on:change="onChangeOrder"
									v-model="orderLimit"
							>
								<option value="20">20</option>
								<option value="50">50</option>
								<option value="100">100</option>
								<option value="150">150</option>
								<option value="200">200</option>

							</select>
						</div>
						<div>
							<paginate
								:page-count="orders.total_page"
								:click-handler="changePageNumber"
								:prev-text="$t('prev')"
								:next-text="$t('next')"
								:container-class="'pagination'">
							</paginate>
						</div>
					</div>
				</div>
			</div>
			<div v-if="tab === 2">
				<div class="contaner">
					<div class="main-card mb-3 card">
						<div class="card-body">
							<div class="row sticky-tble mb-3 mt-3">
								<!-- <div class="col-md-4">
									<span>
										<el-date-picker
										class="w-full app-input"
										v-model="value2"
										type="daterange"
										@change='dateFilterP'
										value-format="yyyy-MM-dd"
										format="yyyy/MM/dd"
										start-placeholder="Startdatum"
										end-placeholder="Slutdatum">
										</el-date-picker>
									</span>
								</div> -->
								<div class="col-md-4">
									<div class="flex w-full">
										<!-- <div class="flex text-xs font-light justify-between align-items-center mr-1 -mt-4">

										</div> -->
										<div class="form-group w-full">
											<select v-model="sub" @change="changeAppName($event, 'product')"
													style="padding-top:0.6rem !important"
													class="text-xs form-select w-full py-2 pt-2">
												<option value="">{{ $t('chooseSubscription') }}:</option>
												<!-- <option value="all" >{{ $t('all') }}</option> -->
												<option :value="app.id" v-for="(app, i) in productSubscription"
														:key="i">{{ app.app_type_name }}
												</option>
											</select>
										</div>
									</div>
								</div>
								<div class="col-md-4"></div>
								<div class="col-md-4">
									<div class="flex justify-start lg:justify-end">
										<div class="form-group w-full">
											<input @input="searchInputFilterOrder($event, 'product')"
												   type="text" :placeholder="$t('search')"
												   class="form-input w-full h-8 text-xs"
												   style="height: 32px !important;">
										</div>
									</div>
								</div>
							</div>

						</div>
					</div>
				</div>
				<div v-if="vloading" class="flex justify-center w-full">
					<svg class="animate-spin -ml-1 mr-3 h-5 w-5 text-gray-400"
						 xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
						<circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
								stroke-width="4"></circle>
						<path class="opacity-75" fill="currentColor"
							  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
					</svg>
				</div>
				<div class="table-container w-full bg-white lg:max-w-[1242px] overflow-x-scroll  rounded">
					<table style="width: 100%;" id="examplemmm"
						   class="table table-hover bg-white table-striped table-bordered table-auto overflow-x-scroll w-full block">
						<thead>
						<tr>
							<th class="text-xs">
													<span class="flex justify-between px-2">
														<span>
														{{ $t('articleNumber') }}
													</span>
													<span class="relative">
													<i class="fa fa-chevron-up absolute bottom-2 cursor-pointer"
													   v-on:click.prevent="sortProduct('number_in_app')"></i>
													<i class="fa fa-chevron-down absolute top-2 cursor-pointer"
													   v-on:click.prevent="sortProduct('-number_in_app')"></i>
												</span>
													</span>
							</th>
							<th class="text-xs">
													<span class="flex justify-between px-2">
														<span>
															{{ $t('articleName') }}
														</span>
														<span class="relative">
															<i class="fa fa-chevron-up absolute bottom-2 cursor-pointer"
															   v-on:click.prevent="sortProduct('name')"></i>
															<i class="fa fa-chevron-down absolute top-2 cursor-pointer"
															   v-on:click.prevent="sortProduct('-name')"></i>
														</span>
													</span>

							</th>
							<th class="text-xs">
												<span class="flex justify-between px-2">
														<span>
														{{ $t('subscription') }}
													</span>
													<span class="relative">
													<i class="fa fa-chevron-up absolute bottom-2 cursor-pointer icon-size"
													   v-on:click.prevent="sortProduct('subscription_id')"></i>
													<i class="fa fa-chevron-down absolute top-2 cursor-pointer icon-size"
													   v-on:click.prevent="sortProduct('-subscription_id')"></i>
												</span>
													</span></th>
							<th class="text-xs">
														<span class="flex justify-between px-2">
														<span>
															Sku
														</span>
														<span class="relative">
															<i class="fa fa-chevron-up absolute bottom-2 cursor-pointer"
															   v-on:click.prevent="sortProduct('sku')"></i>
															<i class="fa fa-chevron-down absolute top-2 cursor-pointer"
															   v-on:click.prevent="sortProduct('-sku')"></i>
														</span>
													</span>
							</th>
							<th class="text-xs" style="min-width:  120px !important;">
														<span class="flex justify-between px-2">
														<span>
															{{ $t('quantity') }}
														</span>
														<span class="relative">
															<i class="fa fa-chevron-up absolute bottom-2 cursor-pointer"
															   v-on:click.prevent="sortProduct('stock_quantity')"></i>
															<i class="fa fa-chevron-down absolute top-2 cursor-pointer"
															   v-on:click.prevent="sortProduct('-stock_quantity')"></i>
														</span>
													</span>
							</th>
							<th class="text-xs" style="min-width:  120px !important;">	<span class="flex justify-between px-2">
														<span>
															{{ $t('price') }}
														</span>
														<span class="relative">
															<i class="fa fa-chevron-up absolute bottom-2 cursor-pointer"
															   v-on:click.prevent="sortProduct('price')"></i>
															<i class="fa fa-chevron-down absolute top-2 cursor-pointer"
															   v-on:click.prevent="sortProduct('-price')"></i>
														</span>
													</span></th>
							<th class="text-xs">
														<span class="flex justify-between px-2">
														<span>
															{{ $t('shop') }}
														</span>
														<span class="relative">
															<i class="fa fa-chevron-up absolute bottom-2 cursor-pointer"
															   v-on:click.prevent="sortProduct('subscription__id')"></i>
															<i class="fa fa-chevron-down absolute top-2 cursor-pointer"
															   v-on:click.prevent="sortProduct('-subscription__id')"></i>
														</span>
													</span>
							</th>
							<th class="text-xs">{{ $t('status') }}</th>
							<th class="text-xs">

														<span class="flex justify-between px-2">
														<span>
															{{ $t('lastUpdated') }}
														</span>
														<span class="relative">
															<i class="fa fa-chevron-up absolute bottom-2 cursor-pointer"
															   v-on:click.prevent="sortProduct('date_updated')"></i>
															<i class="fa fa-chevron-down absolute top-2 cursor-pointer"
															   v-on:click.prevent="sortProduct('-date_updated')"></i>
														</span>
													</span>
							</th>
							<th class="text-xs">

							</th>
						</tr>
						</thead>
						<tbody>
						<ProductTableRowVue :company-id="GET_USER_COMPANY_ID"
											@removeProduct="removeProduct" :data="product"
											v-for="(product, i) in products.results"
											:key="i"/>
						</tbody>
					</table>
				</div>
				<div class="mb-5 mt-3" v-if="products.results.length > 0">
					<div class="flex justify-between">
						<div>
							<select class="text-xs form-select w-full removs-bg" v-on:change="onChangeProductLimit"
									v-model="productLimit"
							>
								<option value="20">20</option>
								<option value="50">50</option>
								<option value="100">100</option>
								<option value="150">150</option>
								<option value="200">200</option>

							</select>
						</div>
						<div>
							<paginate
								:page-count="products.total_page"
								:click-handler="changePageNumberProduct"
								:prev-text="$t('prev')"
								:next-text="$t('next')"
								:container-class="'pagination'">
							</paginate>
						</div>
					</div>
				</div>
				<!--						<div class="float-right mb-5 mt-3" v-if="products.results.length > 0">-->
				<!--							<paginate-->
				<!--								:page-count="products.total_page"-->
				<!--								:click-handler="changePageNumberProduct"-->
				<!--								:prev-text="$t('prev')"-->
				<!--								:next-text="$t('next')"-->
				<!--								:container-class="'pagination'">-->
				<!--							</paginate>-->
				<!--						</div>-->
			</div>

			<!--			</div>-->
		</div>
	</div>
</template>

<script>
import Vue from 'vue'
import {mapGetters} from 'vuex'
import Paginate from 'vuejs-paginate'
import debounce from 'lodash/debounce'
import {DatePicker} from 'element-ui'
import OrderTableRowVue from './components/OrderTableRow.vue'
import {ARTICLE_APP_ONLY, ORDER_APP_ONLY, ORDERS_AND_PRODUCTS} from '../../app/index'
import ProductTableRowVue from './components/ProductTableRow.vue'

Vue.use(DatePicker)

export default {
	name: 'salesPanel',
	components: {
		paginate: Paginate,
		OrderTableRowVue,
		ProductTableRowVue
	},
	data() {
		return {
			orders: {results: []},
			products: {results: []},
			showProductBtn: true,
			showOrderBtn: true,
			sub: '',
			productSortValue: '',
			orderSortValue: '',
			subscriptions: [],
			productSubscription: [],
			orderSubscription: [],
			showSalesPanel: false,
			orderLimit: 20,
			orderPageNum: 1,
			productLimit: 20,
			AppLogs: [],
			copyAppLog: [],
			sytemAppLogs: [],
			copySystemLogs: [],
			value1: '',
			value2: '',
			app_type: [],
			parPage: 10,
			currentPage: 1,
			loading: false,
			currentSystemPage: 1,
			vloading: false,
			tab: 1
		}
	},
	computed: {
		...mapGetters({
			USER_TOKEN: 'auth/USER_TOKEN',
			USER_REFRESH: 'auth/USER_REFRESH',
			GET_SUBSCRIPTION: 'customer/GET_SUBSCRIPTION',
			GET_USER_COMPANY_ID: 'customer/GET_USER_COMPANY_ID',
			GET_SYSTEM_LOGS: 'customer/GET_SYSTEM_LOGS',
			GET_APP_TYPE: 'customer/GET_APP_TYPE',
			GET_APP_LOG: 'customer/GET_APP_LOG'
		})
	},
	mounted() {
		// Implementing two-way fetching
		this.orderLimit = window.localStorage.getItem('orderLimit') !== null ? window.localStorage.getItem('orderLimit') : 20
		window.localStorage.setItem('orderLimit', this.orderLimit)
		this.productLimit = window.localStorage.getItem('productLimit') !== null ? window.localStorage.getItem('productLimit') : 20
		window.localStorage.setItem('productLimit', this.orderLimit)
		if (this.GET_USER_COMPANY_ID) {
			this.getSubscriptionList(this.GET_USER_COMPANY_ID)
			this.getOrders(this.GET_USER_COMPANY_ID)
			this.getProduct(this.GET_USER_COMPANY_ID)
		} else {
			this.getSubscriptionList()
			this.getOrders()
			this.getProduct()
			// this.getAppLogs()
		}


	},
	methods: {
		switchTab: function (tab) {
			this.tab = parseInt(tab)
		},
		sortProduct(sortValue) {
			this.productSortValue = sortValue
			this.vloading = true
			let url = this.$services.endpoints.GET_PRODUCTS + `?page_size=20`
			if (this.GET_USER_COMPANY_ID) {
				url += `&user_id=${this.GET_USER_COMPANY_ID}`
			}
			if (this.sub) {
				url += `&subscription_id=${this.sub}`
			}
			url += `&ordering=${sortValue}`
			this.$store.dispatch('customer/customGetRequest', {URL: url})
				.then(res => {
					this.vloading = false
					this.products = res.data.data
				}).catch(err => {
				this.vloading = false
				if (err.response.status === 400) {
					if (err.response.data.code === 401) {
						this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
							.then(_ => {
								this.changePageNumberProduct(pageNum)
							})
							.catch(_ => {
								this.$store.commit('auth/CLEAR_AUTH_USER', null)
								window.Bus.$emit('sign-out')
							})
					}
				}
			})
		},
		sortOrder(sortValue) {
			this.vloading = true
			this.orderSortValue = sortValue
			let url = this.$services.endpoints.GET_ORDERS + `?page_size=20`
			if (this.GET_USER_COMPANY_ID) {
				url += `&user_id=${this.GET_USER_COMPANY_ID}`
			}
			if (this.sub) {
				url += `&subscription_id=${this.sub}`
			}
			url += `&ordering=${sortValue}`
			this.$store.dispatch('customer/customGetRequest', {URL: url})
				.then(res => {
					this.vloading = false
					this.orders = res.data.data
				}).catch(err => {
				this.vloading = false
				if (err.response.status === 400) {
					if (err.response.data.code === 401) {
						this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
							.then(_ => {
							})
							.catch(_ => {
								this.$store.commit('auth/CLEAR_AUTH_USER', null)
								window.Bus.$emit('sign-out')
							})
					}
				}
			})
		},
		removeProduct: function (product_id) {
			let productIndex = this.products.results.findIndex(item => item.id === product_id)
			if (productIndex > -1) {
				this.products.results.splice(productIndex, 1)
			}
		},
		removeOrder: function (order_id) {
			let orderIndex = this.orders.results.findIndex(item => item.id === order_id)
			if (orderIndex > -1) {
				this.orders.results.splice(orderIndex, 1)
			}
		},
		searchInputFilterOrder: debounce(function (event, mode) {
			if (event.target.value !== '') {
				if (mode === 'order') {
					this.filterInput(event.target.value, 'order')
				} else {
					this.filterInput(event.target.value, 'product')
				}
			} else {
				if (mode === 'order') {
					this.getOrders(this.GET_USER_COMPANY_ID)
				} else {
					this.getProduct(this.GET_USER_COMPANY_ID)
				}
			}
		}, 500),
		changePageNumber(pageNum) {
			this.vloading = true
			this.orderPageNum = pageNum
			let url = this.$services.endpoints.GET_ORDERS + `?page_size=20&page=${Number(this.orderPageNum)}`
			if (this.GET_USER_COMPANY_ID) {
				url += `&user_id=${this.GET_USER_COMPANY_ID}`
			}
			if (this.sub) {
				url += `&subscription_id=${this.sub}`
			}
			if (this.orderSortValue) {
				url += `&ordering=${this.orderSortValue}`
			}
			if (this.value1) {
				url += `&date_from=${this.value1[0]}&date_to=${this.value1[1]}`
			}
			this.$store.dispatch('customer/customGetRequest', {URL: url})
				.then(res => {
					this.vloading = false
					this.orders = res.data.data
				}).catch(err => {
				this.vloading = false
				if (err.response.status === 400) {
					if (err.response.data.code === 401) {
						this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
							.then(_ => {
								// this.changePageNumber(pageNum)
							})
							.catch(_ => {
								this.$store.commit('auth/CLEAR_AUTH_USER', null)
								window.Bus.$emit('sign-out')
							})
					}
				}
			})
		},
		onChangeOrder: function () {
			window.localStorage.setItem('orderLimit', this.orderLimit)
			let url = this.$services.endpoints.GET_ORDERS + `?page_size=${this.orderLimit}&page=${Number(this.orderPageNum)}`
			if (this.GET_USER_COMPANY_ID) {
				url += `&user_id=${this.GET_USER_COMPANY_ID}`
			}
			if (this.sub) {
				url += `&subscription_id=${this.sub}`
			}
			if (this.orderSortValue) {
				url += `&ordering=${this.orderSortValue}`
			}
			if (this.value1) {
				url += `&date_from=${this.value1[0]}&date_to=${this.value1[1]}`
			}
			this.vloading = true
			this.$store.dispatch('customer/customGetRequest', {URL: url})
				.then(res => {
					this.vloading = false
					this.orders = res.data.data
				}).catch(err => {
				this.vloading = false
				if (err.response.status === 400) {
					if (err.response.data.code === 401) {
						this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
							.then(_ => {
								// this.changePageNumber(pageNum)
							})
							.catch(_ => {
								this.$store.commit('auth/CLEAR_AUTH_USER', null)
								window.Bus.$emit('sign-out')
							})
					}
				}
			})

		},
		changePageNumberProduct(pageNum) {
			let url = this.$services.endpoints.GET_PRODUCTS + `?page_size=${this.productLimit}&page=${Number(pageNum)}`
			if (this.GET_USER_COMPANY_ID) {
				url += `&user_id=${this.GET_USER_COMPANY_ID}`
			}
			if (this.sub) {
				url += `&subscription_id=${this.sub}`
			}
			if (this.productSortValue) {
				url += `&ordering=${this.productSortValue}`
			}
			this.$store.dispatch('customer/customGetRequest', {URL: url})
				.then(res => {
					this.vloading = false
					this.products = res.data.data
				}).catch(err => {
				this.vloading = false
				if (err.response.status === 400) {
					if (err.response.data.code === 401) {
						this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
							.then(_ => {
							})
							.catch(_ => {
								this.$store.commit('auth/CLEAR_AUTH_USER', null)
								window.Bus.$emit('sign-out')
							})
					}
				}
			})
		},
		onChangeProductLimit() {
			window.localStorage.setItem('productLimit', this.productLimit)
			let url = this.$services.endpoints.GET_PRODUCTS + `?page_size=${this.productLimit}`
			if (this.GET_USER_COMPANY_ID) {
				url += `&user_id=${this.GET_USER_COMPANY_ID}`
			}
			if (this.sub) {
				url += `&subscription_id=${this.sub}`
			}
			if (this.productSortValue) {
				url += `&ordering=${this.productSortValue}`
			}
			this.$store.dispatch('customer/customGetRequest', {URL: url})
				.then(res => {
					this.vloading = false
					this.products = res.data.data
				}).catch(err => {
				this.vloading = false
				if (err.response.status === 400) {
					if (err.response.data.code === 401) {
						this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
							.then(_ => {
							})
							.catch(_ => {
								this.$store.commit('auth/CLEAR_AUTH_USER', null)
								window.Bus.$emit('sign-out')
							})
					}
				}
			})
		},
		dateFilterOrder(e) {
			if (e) {
				this.vloading = true
				let URL = this.$services.endpoints.GET_ORDERS + `?page_size=${this.orderLimit}`
				if (this.GET_USER_COMPANY_ID) {
					URL += `&user_id=${this.GET_USER_COMPANY_ID}`
				}
				if (this.sub) {
					URL += `&subscription_id=${this.sub}`
				}

				URL += `&date_from=${e[0]}&date_to=${e[1]}`

				this.$store.dispatch('customer/customGetRequest', {URL})
					.then(res => {
						this.vloading = false
						this.orders = res.data.data
					}).catch(err => {
					this.vloading = false
					if (err.response.status === 400) {
						if (err.response.data.code === 401) {
							this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
								.then(_ => {
									this.dateFilterOrder(e)
								})
								.catch(_ => {
									this.$store.commit('auth/CLEAR_AUTH_USER', null)
									window.Bus.$emit('sign-out')
								})
						}
					}
				})
			} else {
				if (this.GET_USER_COMPANY_ID) {
					this.getOrders(this.GET_USER_COMPANY_ID)
				} else {
					this.getOrders()
				}

			}

		},
		filterInput(value, input) {
			this.vloading = true
			// const mode = input === 'order' ? this.$services.endpoints.GET_ORDERS : this.$services.endpoints.GET_PRODUCTS
			// const payload = {
			// 	URL: this.GET_USER_COMPANY_ID ?
			// 		(this.sub ? mode + `${this.GET_USER_COMPANY_ID}/?subscription_id=${this.sub}&search=${value}` : mode + `${this.GET_USER_COMPANY_ID}/?search=${value}`) :
			// 		(this.sub ? mode + `?subscription_id=${this.sub}&search=${value}` : mode + `?search=${value}`)
			// }
			let URL = ''
			if (input === 'order') {
				URL = this.$services.endpoints.GET_ORDERS + `?page_size=${this.orderLimit}`
			} else {
				URL = this.$services.endpoints.GET_PRODUCTS + `?page_size=${this.productLimit}`
			}
			if (this.GET_USER_COMPANY_ID) {
				URL += `&user_id=${this.GET_USER_COMPANY_ID}`
			}
			if (this.sub) {
				URL += `&subscription_id=${this.sub}`
			}
			URL += `&search=${value}`
			this.orderSortValue = ''
			this.productSortValue = ''
			this.$store.dispatch('customer/customGetRequest', {URL})
				.then(res => {
					this.vloading = false
					if (input === 'order') {
						this.orders = res.data.data
					} else {
						this.products = res.data.data
					}
				}).catch(err => {
				this.vloading = false
				if (err.response.status === 400) {
					if (err.response.data.code === 401) {
						this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
							.then(_ => {
								this.filterInput(value, input)
							})
							.catch(_ => {
								this.$store.commit('auth/CLEAR_AUTH_USER', null)
								window.Bus.$emit('sign-out')
							})
					}
				}

			})
		},
		changeAppName(e, input) {
			this.vloading = true
			let mode = input === 'order' ? this.$services.endpoints.GET_ORDERS + `?page_size=${this.orderLimit}` : this.$services.endpoints.GET_PRODUCTS + `?page_size=${this.productLimit}`
			// const payload = {
			// 	URL: this.GET_USER_COMPANY_ID ?
			// 		mode + `${this.GET_USER_COMPANY_ID}/?subscription_id=${this.sub}` :
			// 		mode + `?subscription_id=${this.sub}`
			// }
			if (this.GET_USER_COMPANY_ID) {
				mode += `&user_id=${this.GET_USER_COMPANY_ID}`
			}
			mode += `&subscription_id=${this.sub}`
			this.orderSortValue = ''
			this.productSortValue = ''
			this.$store.dispatch('customer/customGetRequest', {URL: mode})
				.then(res => {
					this.vloading = false
					if (input === 'order') {
						this.orders = res.data.data
					} else {
						this.products = res.data.data
					}
				}).catch(err => {
				this.vloading = false
				if (err.response.status === 400) {
					if (err.response.data.code === 401) {
						this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
							.then(_ => {
								this.changeAppName(e, input)
							})
							.catch(_ => {
								this.$store.commit('auth/CLEAR_AUTH_USER', null)
								window.Bus.$emit('sign-out')
							})
					}
				}
			})
		},
		dateFilter(e) {
			this.$store.commit('customer/FILTER_SYSTEM_DATE', e)
		},
		dateFilterAudit(e) {
			this.$store.commit('customer/FILTER_AUDIT_DATE', e)
		},
		getOrders(userID = null) {
			this.vloading = true
			this.orderSortValue = ''
			this.productSortValue = ''
			const URL = userID ? this.$services.endpoints.GET_ORDERS + `?user_id=${userID}&page_size=${this.orderLimit}` : this.$services.endpoints.GET_ORDERS + `?page_size=${this.orderLimit}`
			this.$store.dispatch('customer/customGetRequest', {URL})
				.then(res => {
					this.vloading = false
					this.orders = res.data.data
				}).catch((err) => {
				this.vloading = false
				if (err.response.status === 400) {
					if (err.response.data.code === 401) {
						this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
							.then(_ => {
								this.getProduct(this.GET_USER_COMPANY_ID)
							})
							.catch(_ => {
								this.$store.commit('auth/CLEAR_AUTH_USER', null)
								window.Bus.$emit('sign-out')
							})
					}
				}
			})
		},
		getProduct(userID = null) {
			this.vloading = true
			this.orderSortValue = ''
			this.productSortValue = ''
			const URL = userID ? this.$services.endpoints.GET_PRODUCTS + `?user_id=${userID}&page_size=${this.productLimit}` : this.$services.endpoints.GET_PRODUCTS + `?page_size=${this.productLimit}`
			this.$store.dispatch('customer/customGetRequest', {URL})
				.then(res => {
					this.vloading = false
					this.products = res.data.data
				}).catch((err) => {
				this.vloading = false
				if (err.response.status === 400) {
					if (err.response.data.code === 401) {
						this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
							.then(_ => {
								this.getProduct(this.GET_USER_COMPANY_ID)
							})
							.catch(_ => {
								this.$store.commit('auth/CLEAR_AUTH_USER', null)
								window.Bus.$emit('sign-out')
							})
					}
				}
			})
		},
		getSubscriptionList(userID) {
			const URL = userID ? this.$services.endpoints.GET_USER_SUBSCRIPTION + `${userID}/` : this.$services.endpoints.GET_USER_SUBSCRIPTION
			this.$store.dispatch('customer/getSubscriptionList', {URL: URL})
				.then(_ => {
					this.showProductBtn = true
					this.showOrderBtn = true
					this.productSubscription = this.GET_SUBSCRIPTION.all_subscriptions.filter(item => ARTICLE_APP_ONLY.includes(item.app_type) === true)
					this.orderSubscription = this.GET_SUBSCRIPTION.all_subscriptions.filter(item => ORDER_APP_ONLY.includes(item.app_type) === true)
					if (this.productSubscription.length <= 0) {
						this.showProductBtn = false
					}
					if (this.orderSubscription.length <= 0) {
						this.showOrderBtn = false
					}
					this.showSalesPanel = true
				}).catch((err) => {
				if (err.response.status === 400) {
					if (err.response.data.code === 401) {
						this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
							.then(_ => {
								if (this.GET_USER_COMPANY_ID) {
									this.getSubscriptionList(this.GET_USER_COMPANY_ID)
								} else {
									this.getSubscriptionList()
								}
							})
							.catch(_ => {
								this.$store.commit('auth/CLEAR_AUTH_USER', null)
								window.Bus.$emit('sign-out')
							})
					}
				}
			})
		}

	}
}
</script>

<style lang="scss" scoped>
@import "./../../assets/variables.scss";

.icon-size {
	font-size: 10px !important;
}

.form-select {
	background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%23a0aec0'%3e%3cpath d='M15.3 9.3a1 1 0 0 1 1.4 1.4l-4 4a1 1 0 0 1-1.4 0l-4-4a1 1 0 0 1 1.4-1.4l3.3 3.29 3.3-3.3z'/%3e%3c/svg%3e");
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	-webkit-print-color-adjust: exact;
	color-adjust: exact;
	background-repeat: no-repeat;
	background-color: #fff;
	border-color: #e2e8f0;
	border-width: 1px;
	border-radius: 0.25rem;
	padding-top: 0.3rem !important;
	padding-right: 2.5rem;
	padding-bottom: 0.5rem;
	padding-left: 0.75rem;
	font-size: 0.9rem !important;
	line-height: 1.5;
	background-position: right 0.5rem center;
	background-size: 1.5em 1.5em;
}

.sticky-table {
	position: -webkit-sticky;
	position: sticky;
	top: 61px;
	background-color: #fff;
	// background-color: #e8eaec;
	height: 50px;
	padding: 7px;
}

.card-body {
	padding: 0 0.9rem !important;
}

.app-main .app-main__inner {
	padding-top: 20px !important;
}

.body-tabs-shadow .body-tabs-animated {
	padding: unset !important;
}

.tab-btn.active {
	box-shadow: unset !important;
	border: 1px solid $theme-secondary-color !important;
	font-weight: 800;
	color: $theme-secondary-color !important;
	border-radius: 5px;
}

.tab-btn {
	color: $theme-secondary-light;
}

.remove-bg {
	background: unset !important;
	background-color: unset !important;
}

//th, td {
//	min-width: 200px;
//}


.text-xs-custom {
	font-size: 14px !important;
	line-height: 1rem !important;
}
</style>
